<template>
  <BaseSelect
    v-model="model"
    class="d-inline-block"
    :options="options"
    label="text"
    :clearable="false"
    :reduce="item => item.value"
    @selected="onSelected"
  >
    <template #selected-option="{ text, value }">
      <b-badge
        pill
        :variant="value === 1 ? 'light-success' : 'light-danger'"
        class="px-1 d-inline-flex align-items-center"
      >
        <feather-icon
          :icon="value === 1 ? 'CheckCircleIcon' : 'XCircleIcon'"
          size="25"
          :color="value === 1 ? 'success' : 'danger'"
        />
        <span :class="{ 'text-capitalize font-weight-light badge-text': true, 'text-danger': value !== 1, 'text-success': value === 1 }">
          {{ text }}
        </span>
      </b-badge>
    </template>
  </BaseSelect>
</template>

<script>
import BaseSelect from '@/components/ui/select/BaseSelect.vue'

export default {
  name: 'BinarySelect',
  components: { BaseSelect },
  props: {
    value: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      options: [
        { text: 'Si', value: 1 },
        { text: 'No', value: 0 },
      ],
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newModel) {
        this.$emit('input', newModel)
      },
    },
  },
  methods: {
    onSelected(event) {
      this.$emit('change', event)
    },
  },
}
</script>

<style scoped>
.v-select {
  min-width: 7em;
}

.badge-text {
  padding-left: 7px;
  padding-top: 3px;
}

</style>
