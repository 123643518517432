<template>
  <v-select
    :id="id"
    v-model="model"
    :options="options"
    :reduce="reduce"
    placeholder="Tipo de campo"
    transition=""
    :append-to-body="appendToBody"
    :clearable="clearable"
    @option:selected="onSelected"
  />
</template>

<script>
export default {
  name: 'CategoryAttributeTypeSelect',
  props: {
    value: {
      type: [Object, String, Number],
      default: null,
    },
    id: {
      type: String,
      default: null,
    },
    reduce: {
      type: Function,
      default: item => item,
    },
    appendToBody: {
      type: Boolean,
      default: false,
    },
    clearable: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      options: ['input', 'select'],
    }
  },
  computed: {
    model: {
      get() {
        return this.value
      },
      set(newModel) {
        this.$emit('input', newModel)
      },
    },
  },
  methods: {
    onSelected(event) {
      this.$emit('change', event)
    },
  },
}
</script>

<style scoped>
.v-select {
  min-width: 7em;
}
</style>
