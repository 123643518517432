<template>
  <b-modal
    v-model="model"
    size="lg"
    hide-footer
    centered
  >
    <b-overlay
      :show="loading"
      variant="white"
      spinner-variant="primary"
      rounded="sm"
    >
      <h1 class="font-large-1 text-center pt-2">
        Nuevo atributo para la categoría
      </h1>
      <validation-observer ref="category-attribute-creation-form">
        <b-form
          class="py-2"
          @submit.prevent
        >
          <b-row align-h="center">
            <b-col
              sm="12"
              lg="10"
            >
              <validation-provider
                v-slot="validationContext"
                name="Nombre"
                rules="required"
              >
                <b-form-group
                  label="Nombre"
                  label-for="category-attribute-name"
                >
                  <b-form-input
                    v-model="form.name"
                    name="category-attribute-name"
                    placeholder="Nombre"
                    :state="$getValidationState(validationContext)"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              sm="12"
              lg="10"
            >
              <validation-provider
                v-slot="validationContext"
                name="Tipo de campo"
                rules="required"
              >
                <b-form-group
                  label="Tipo de campo"
                  label-for="category-attribute-type"
                >
                  <CategoryAttributeTypeSelect
                    id="category-attribute-type"
                    v-model="form.type"
                  />
                  <small class="text-danger">{{ validationContext.errors[0] }}</small>
                </b-form-group>
              </validation-provider>
            </b-col>
            <b-col
              v-show="optionsFieldVisible"
              sm="12"
              lg="10"
            >
              <b-form-group
                label="Opciones"
                label-for="category-attribute-options"
              >
                <b-form-input
                  v-model="form.options"
                  name="category-attribute-options"
                  placeholder="Opciones"
                />
              </b-form-group>
            </b-col>
            <b-col
              sm="12"
              lg="10"
            >
              <b-form-checkbox
                v-model="form.required"
                class="pb-1"
                value="1"
                :unchecked-value="0"
              >
                Obligatorio
              </b-form-checkbox>
            </b-col>
          </b-row>
        </b-form>
        <b-row align-h="center">
          <b-col cols="auto">
            <b-button
              variant="primary"
              class="text-nowrap rounded-sm px-2"
              @click="handleAcceptButtonClick"
            >
              Aceptar
            </b-button>
          </b-col>
        </b-row>
      </validation-observer>
    </b-overlay>
  </b-modal>
</template>

<script>
import ProductAttrConfigurationApi from '@/api/product-attr-configuration-api'
import { mapActions, mapGetters } from 'vuex'
import CategoryAttributeTypeSelect from '@/components/category/select/CategoryAttributeTypeSelect.vue'

export default {
  name: 'CategoryAttributeCreationModal',
  components: { CategoryAttributeTypeSelect },
  props: {
    value: {
      default: false,
      type: Boolean,
    },
  },
  data() {
    return {
      loading: false,
      form: {
        name: null,
        type: null,
        required: 0,
        options: null,
      },
    }
  },
  computed: {
    ...mapGetters('category', {
      category: 'getCategory',
    }),
    model: {
      get() {
        return this.value
      },
      set(newModel) {
        this.$emit('input', newModel)
      },
    },
    optionsFieldVisible() {
      return this.form.type === 'select'
    },
  },
  methods: {
    ...mapActions('category', ['loadCategoryAttributes']),
    async handleAcceptButtonClick() {
      const valid = await this.$refs['category-attribute-creation-form'].validate()
      if (!valid) {
        return
      }

      this.loading = true
      try {
        this.form.product_category_id = this.category?.id
        await ProductAttrConfigurationApi.create(this.form)
        await this.loadCategoryAttributes(this.category?.id)
        this.clearForm()
        this.model = false
      } finally {
        this.loading = false
      }
    },
    clearForm() {
      this.form = {
        name: null,
        category: null,
        required: 0,
      }
    },
  },
}
</script>

<style scoped>

</style>
