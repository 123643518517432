<template>
  <BaseCard
    actions-visible
    title="Campos personalizados"
    :resource="$data.$constants.RESOURCES.RESOURCE_CATEGORIES"
    :caption-visible="false"
    :loading="loading"
    :footer-visible="false"
  >
    <template #actions>
      <b-link @click="creationModalVisible = true">
        <b-button
          variant="primary"
          class="text-nowrap rounded-sm px-2"
        >
          + Añadir
        </b-button>
      </b-link>
    </template>
    <b-row
      class="px-2 py-1 bg-table-headings flex-nowrap header-row"
      no-gutters
    >
      <b-col class="font-weight-bold column-min-width px-2">
        NOMBRE
      </b-col>
      <b-col class="font-weight-bold column-min-width px-2">
        TIPO DE CAMPOS
      </b-col>
      <b-col class="font-weight-bold column-min-width px-2">
        OPCIONES
      </b-col>
      <b-col class="font-weight-bold column-min-width px-2">
        OBLIGATORIO
      </b-col>
      <b-col
        cols="auto"
        class="px-3"
      />
    </b-row>
    <Tree
      ref="category-tree"
      v-slot="{ node }"
      v-model="categoryTree"
      class="category-attribute-tree"
      :indent="50"
      dragging-node-position-mode="mouse"
      @drop="handleDropCategory"
    >
      <b-row
        no-gutters
        class="p-2 border-0 cursor-move category-tree-node flex-nowrap"
        align-v="center"
        align-h="between"
      >
        <b-col class="column-min-width px-2">
          <b-form-input
            v-model="node.name"
            placeholder="Nombre"
            @change="updateCategoryAttribute(node)"
          />
        </b-col>

        <b-col class="column-min-width px-2">
          <CategoryAttributeTypeSelect
            v-model="node.type"
            :append-to-body="true"
            :clearable="false"
            class="d-inline-block"
            @change="updateCategoryAttribute(node)"
          />
        </b-col>
        <b-col class="column-min-width px-2">
          <b-form-input
            v-model="node.options"
            placeholder="Opciones"
            :disabled="node.type === 'input'"
            @change="updateCategoryAttribute(node)"
          />
        </b-col>
        <b-col class="column-min-width px-2">
          <BinarySelect
            v-model="node.required"
            @change="updateCategoryAttribute(node)"
          />
        </b-col>
        <b-col
          cols="auto"
          class="d-flex justify-content-center px-2"
        >
          <span>
            <b-link
              class="d-inline-block px-50 text-danger"
              @click="handleDeleteButtonClick(node)"
            >
              <feather-icon
                v-b-tooltip.hover 
                title="Eliminar"
                icon="TrashIcon"
                size="18"
              />
            </b-link>
          </span>
        </b-col>
      </b-row>
    </Tree>
    <CategoryAttributeCreationModal v-model="creationModalVisible" />
  </BaseCard>
</template>

<script>
import { mapGetters, mapActions } from 'vuex'
import { Tree, Draggable } from 'he-tree-vue'
import ProductAttrConfigurationApi from '@/api/product-attr-configuration-api'
import CategoryAttributeTypeSelect from '@/components/category/select/CategoryAttributeTypeSelect.vue'
import CategoryAttributeCreationModal from '@/components/category/modal/CategoryAttributeCreationModal.vue'
import BinarySelect from '@/components/ui/select/BinarySelect.vue'
import BaseCard from '@/components/ui/card/BaseCard.vue'

export default {
  name: 'CategoryAttributesList',
  components: {
    BaseCard,
    CategoryAttributeCreationModal,
    BinarySelect,
    CategoryAttributeTypeSelect,
    Tree: Tree.mixPlugins([Draggable]),
  },
  props: {
    categoryId: {
      type: Number,
      default: null,
    },
  },
  data() {
    return {
      loading: false,
      creationModalVisible: false,
    }
  },
  computed: {
    ...mapGetters('category', {
      category: 'getCategory',
      categoryAttributes: 'getCategoryAttributes',
    }),
    categoryTree: {
      get() {
        return this.categoryAttributes
      },
      set(categoryAttributes) {
        this.setCategoryAttributes(categoryAttributes)
      },
    },
  },
  mounted() {
    this.fetchCategoryAttributes()
  },
  methods: {
    ...mapActions('category', ['loadCategoryAttributes', 'setCategoryAttributes']),
    async fetchCategoryAttributes() {
      if (!this.categoryId) {
        return
      }

      this.loading = true
      try {
        await this.loadCategoryAttributes(this.categoryId)
      } catch (error) {
        console.error(error)
        this.$toast.error('Ocurrió un error al obtener la informacion de los atributos de la categoría. '
            + 'Intentlo de nuevo mas tarde.')
      }

      this.loading = false
    },
    async handleDropCategory() {
      this.loading = true
      try {
        const categoryAttributeIds = this.getCategoryAttributeIds()
        await ProductAttrConfigurationApi.setCategoryAttributesOrder(categoryAttributeIds)
        await this.loadCategoryAttributes(this.categoryId)
      } finally {
        this.loading = false
      }
    },
    getCategoryAttributeIds() {
      const ids = []
      this.categoryTree.forEach(categoryAttribute => {
        ids.push(categoryAttribute.id)
        if (categoryAttribute.children && categoryAttribute.children.length > 0) {
          ids.push(categoryAttribute.children[0].id)
        }
      })

      return ids
    },
    async handleDeleteButtonClick(categoryAttribute) {
      const response = await this.$modal.show({
        title: '¿Está seguro?',
        text: `Se borrará el registro ${categoryAttribute.name}.`,
      })

      if (!response.isConfirmed) {
        return
      }

      this.loading = true
      try {
        await ProductAttrConfigurationApi.delete(categoryAttribute.id)
        await this.loadCategoryAttributes(this.categoryId)
      } finally {
        this.loading = false
      }
    },
    async updateCategoryAttribute(categoryAttribute) {
      this.loading = true
      try {
        await ProductAttrConfigurationApi.edit(
          categoryAttribute.id,
          { ...categoryAttribute, product_category_id: this.categoryId },
        )
        await this.loadCategoryAttributes(this.categoryId)
      } finally {
        this.loading = false
      }
    },
  },
}
</script>

<style scoped>
.column-min-width {
  min-width: 200px;
}

>>> .vs__dropdown-toggle {
  border: 0;
}

.category-attribute-tree >>> .tree-node-back {
  padding-left: 0 !important;
}

.header-row {
  min-width: fit-content;
}
</style>
